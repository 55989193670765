@charset "UTF-8";
/* **************************************


     追加


***************************************/
body {
  font-size: 1.6rem;
  font-family: "Zen Maru Gothic", serif;
  font-style: normal;
  line-height: 1.36364;
  font-weight: 600;
  color: #5b3616;
}

/*==================================================
ふわっ
===================================*/
/* その場で */
.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 下から */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 上から */
.fadeDown {
  animation-name: fadeDownAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeDownAnime {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 左から */
.fadeLeft {
  animation-name: fadeLeftAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeLeftAnime {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* 右から */
.fadeRight {
  animation-name: fadeRightAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeRightAnime {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeInTrigger,
.fadeUpTrigger,
.fadeDownTrigger,
.fadeLeftTrigger,
.fadeRightTrigger {
  opacity: 0;
}

/*==================================================
パタッ
===================================*/
/* 下へ */
.flipDown {
  animation-name: flipDownAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes flipDownAnime {
  from {
    transform: perspective(2500px) rotateX(100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2500px) rotateX(0);
    opacity: 1;
  }
}

/* 左へ */
.flipLeft {
  animation-name: flipLeftAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  perspective-origin: left center;
  opacity: 0;
}

@keyframes flipLeftAnime {
  from {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(30deg);
    opacity: 0;
  }
  to {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(0deg);
    opacity: 1;
  }
}

/* 左上へ */
.flipLeftTop {
  animation-name: flipLeftTopAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes flipLeftTopAnime {
  from {
    transform: translate(-20px, 80px) rotate(-15deg);
    opacity: 0;
  }
  to {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
}

/* 右へ */
.flipRight {
  animation-name: flipRightAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  perspective-origin: right center;
  opacity: 0;
}

@keyframes flipRightAnime {
  from {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(-30deg);
    opacity: 0;
  }
  to {
    transform: perspective(600px) translate3d(0, 0, 0) rotateY(0deg);
    opacity: 1;
  }
}

/* 右上へ */
.flipRightTop {
  animation-name: flipRightTopAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes flipRightTopAnime {
  from {
    transform: translate(-20px, 80px) rotate(25deg);
    opacity: 0;
  }
  to {
    transform: translate(0, 1) rotate(0deg);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.flipDownTrigger,
.flipLeftTrigger,
.flipLeftTopTrigger,
.flipRightTrigger,
.flipRightTopTrigger {
  opacity: 0;
}

/*==================================================
くるっ
===================================*/
/* X 軸（縦へ） */
.rotateX {
  animation-name: rotateXAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateXAnime {
  from {
    transform: rotateX(0);
    opacity: 0;
  }
  to {
    transform: rotateX(-360deg);
    opacity: 1;
  }
}

/*　Y軸（横へ） */
.rotateY {
  animation-name: rotateYAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateYAnime {
  from {
    transform: rotateY(0);
    opacity: 0;
  }
  to {
    transform: rotateY(-360deg);
    opacity: 1;
  }
}

/* Z 軸（左へ） */
.rotateLeftZ {
  animation-name: rotateLeftZAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateLeftZAnime {
  from {
    transform: rotateZ(0);
    opacity: 0;
  }
  to {
    transform: rotateZ(-360deg);
    opacity: 1;
  }
}

/*　Z 軸（右へ） */
.rotateRightZ {
  animation-name: rotateRightZAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes rotateRightZAnime {
  from {
    transform: rotateZ(0);
    opacity: 0;
  }
  to {
    transform: rotateZ(360deg);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.rotateXTrigger,
.rotateYTrigger,
.rotateLeftZTrigger,
.rotateRightZTrigger {
  opacity: 0;
}

/*==================================================
ボンッ、ヒュッ
===================================*/
/* 拡大 */
.zoomIn {
  animation-name: zoomInAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes zoomInAnime {
  from {
    transform: scale(0.6);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* 拡大 */
.zoomInLeft {
  animation-name: zoomInLeftAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes zoomInLeftAnime {
  from {
    transform: scale(0.6) translateX(-50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translateX(0px);
    opacity: 1;
  }
}

/* 拡大 */
.zoomInRight {
  animation-name: zoomInRightAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes zoomInRightAnime {
  from {
    transform: scale(0.6) translateX(50%);
    opacity: 0;
  }
  to {
    transform: scale(1) translateX(0%);
    opacity: 1;
  }
}

/* 縮小 */
.zoomOut {
  animation-name: zoomOutAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes zoomOutAnime {
  from {
    transform: scale(1.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.zoomInTrigger,
.zoomOutTrigger,
.zoomInLeftTrigger,
.zoomInRightTrigger {
  opacity: 0;
}

/*==================================================
じわっ
===================================*/
/* ぼかしから出現 */
.blur {
  animation-name: blurAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes blurAnime {
  from {
    filter: blur(10px);
    transform: scale(1.02);
    opacity: 0;
  }
  to {
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.blurTrigger {
  opacity: 0;
}

/*==================================================
にゅーん
===================================*/
/* 滑らかに変形して出現 */
.smooth {
  animation-name: smoothAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  transform-origin: left;
  opacity: 0;
}

@keyframes smoothAnime {
  from {
    transform: translate3d(0, 100%, 0) skewY(12deg);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) skewY(0);
    opacity: 1;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.smoothTrigger {
  opacity: 0;
}

/*==================================================
スーッ（枠線が伸びて出現）
===================================*/
/*枠線が伸びて出現*/
.lineTrigger {
  position: relative;
  /* 枠線が書かれる基点*/
  opacity: 0;
}

.lineTrigger.lineanime {
  animation-name: lineAnimeBase;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes lineAnimeBase {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*上下線*/
.lineTrigger::before,
.lineTrigger::after {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  background: #333;
  /* 枠線の色*/
}

/*左右線*/
.line2::before,
.line2::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 0;
  background: #333;
  /* 枠線の色*/
}

/*上線*/
.lineTrigger::before {
  top: 0;
  left: 0;
}

.lineTrigger.lineanime::before {
  animation: lineAnime 0.5s linear 0s forwards;
  /*表示されて0秒後に上線が0.5秒かけて表示*/
}

/*右線*/
.line2::before {
  top: 0;
  right: 0;
}

.lineTrigger.lineanime .line2::before {
  animation: lineAnime2 0.5s linear 0.5s forwards;
  /*表示されて0.5秒後に右線が0.5秒かけて表示*/
}

/*下線*/
.lineTrigger::after {
  bottom: 0;
  right: 0;
}

.lineTrigger.lineanime::after {
  animation: lineAnime 0.5s linear 1s forwards;
  /*表示されて1秒後に下線が0.5秒かけて表示*/
}

/*左線*/
.line2::after {
  bottom: 0;
  left: 0;
}

.lineTrigger.lineanime .line2::after {
  animation: lineAnime2 0.5s linear 1.5s forwards;
  /*表示されて1.5秒後に左線が0.5秒かけて表示*/
}

@keyframes lineAnime {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes lineAnime2 {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

/*枠線内側の要素*/
.lineTrigger.lineanime .lineinappear {
  animation: lineInnerAnime 0.5s linear 1.5s forwards;
  /*1.5秒後に中央のエリアが0.5秒かけて表示*/
  opacity: 0;
  /*初期値を透過0にする*/
}

@keyframes lineInnerAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*==================================================
シャッ（背景色が伸びて出現）
===================================*/
/*背景色が伸びて出現（共通）*/
.bgextend {
  animation-name: bgextendAnimeBase;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: relative;
  overflow: hidden;
  /*　はみ出た色要素を隠す　*/
  opacity: 0;
}

@keyframes bgextendAnimeBase {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*中の要素*/
.bgappear {
  animation-name: bgextendAnimeSecond;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes bgextendAnimeSecond {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*左から*/
.bgLRextend::before {
  animation-name: bgLRextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgLRextendAnime {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  50% {
    transform-origin: left;
    transform: scaleX(1);
  }
  50.001% {
    transform-origin: right;
  }
  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}

@keyframes bgLRextendAnime {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  50% {
    transform-origin: left;
    transform: scaleX(1);
  }
  50.001% {
    transform-origin: right;
  }
  100% {
    transform-origin: right;
    transform: scaleX(0);
  }
}

/*右から*/
.bgRLextend::before {
  animation-name: bgRLextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgRLextendAnime {
  0% {
    transform-origin: right;
    transform: scaleX(0);
  }
  50% {
    transform-origin: right;
    transform: scaleX(1);
  }
  50.001% {
    transform-origin: left;
  }
  100% {
    transform-origin: left;
    transform: scaleX(0);
  }
}

/*下から*/
.bgDUextend::before {
  animation-name: bgDUextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgDUextendAnime {
  0% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
  50% {
    transform-origin: bottom;
    transform: scaleY(1);
  }
  50.001% {
    transform-origin: top;
  }
  100% {
    transform-origin: top;
    transform: scaleY(0);
  }
}

/*上から*/
.bgUDextend::before {
  animation-name: bgUDextendAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #666;
  /*伸びる背景色の設定*/
}

@keyframes bgUDextendAnime {
  0% {
    transform-origin: top;
    transform: scaleY(0);
  }
  50% {
    transform-origin: top;
    transform: scaleY(1);
  }
  50.001% {
    transform-origin: bottom;
  }
  100% {
    transform-origin: bottom;
    transform: scaleY(0);
  }
}

.LR {
  white-space: nowrap;
  overflow: hidden;
  animation: LRAnime 1.5s forwards linear;
  width: 0;
  margin: 0;
}

@keyframes LRAnime {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.TB {
  white-space: nowrap;
  overflow: hidden;
  animation: TBAnime 1.5s forwards linear;
  height: 0;
  margin: 0;
  display: inline-block;
}

@keyframes TBAnime {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.bgappearTrigger,
.bgUDextendTrigger,
.bgDUextendTrigger,
.bgRLextendTrigger,
.bgLRextendTrigger {
  opacity: 0;
}

.flowing {
  width: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  animation: flowing-anim 1s forwards linear;
  display: block;
}

@keyframes flowing-anim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

/* .flowingTrigger {
  opacity: 0;
} */
/*========= レイアウトのためのCSS ===============*/
.wrapper {
  overflow: hidden;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.box {
  width: 220px;
  padding: 20px;
  margin: 0 20px 20px 20px;
  background: #666;
  color: #fff;
  box-sizing: border-box;
}

.bgextend,
.lineTrigger {
  width: 220px;
  padding: 20px;
  margin: 0 20px 20px 20px;
  box-sizing: border-box;
}

/*==================================================
アニメーション設定
===================================*/
/* アニメーションの回数を決めるCSS*/
.count2 {
  animation-iteration-count: 2;
  /*この数字を必要回数分に変更*/
}

.countinfinite {
  animation-iteration-count: infinite;
  /*無限ループ*/
}

/* アニメーションスタートの遅延時間を決めるCSS*/
.delay-time01 {
  animation-delay: 0.1s;
}

.delay-time02 {
  animation-delay: 0.2s;
}

.delay-time03 {
  animation-delay: 0.3s;
}

.delay-time04 {
  animation-delay: 0.4s;
}

.delay-time04._sp {
  animation-delay: 0.4s;
}

@media (max-width: 767px) {
  .delay-time01 {
    animation-delay: 0s;
  }
  .delay-time02 {
    animation-delay: 0s;
  }
  .delay-time04 {
    animation-delay: 0s;
  }
}

.delay-time05 {
  animation-delay: 0.5s;
}

.delay-time1 {
  animation-delay: 1s;
}

.delay-time15 {
  animation-delay: 1.5s;
}

.delay-time2 {
  animation-delay: 2s;
}

.delay-time25 {
  animation-delay: 2.5s;
}

/* アニメーション自体が変化する時間を決めるCSS*/
.change-time05 {
  animation-duration: 0.5s;
}

.change-time1 {
  animation-duration: 1s;
}

.change-time15 {
  animation-duration: 1.5s;
}

.change-time2 {
  animation-duration: 2s;
}

.change-time25 {
  animation-duration: 2.5s;
}

[data-target] {
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fcf5ee;
  overflow-wrap: break-word;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  letter-spacing: 0.07em;
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
  visibility: hidden;
  opacity: 0;
  transition: all 1s;
  font-feature-settings: "palt";
  overflow-x: hidden;
  z-index: 1;
}

body[data-loading="true"] {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 767px) {
  body {
    font-size: 1.4rem;
    min-width: unset;
  }
}

body.is-checked {
  overflow: hidden;
}

ul,
li {
  list-style: none;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: top;
}

video {
  max-width: 100%;
  height: auto;
}

code,
kbd,
pre,
samp {
  font-size: inherit;
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

th,
td {
  padding: 4px 6px;
  text-align: left;
  vertical-align: top;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before,
q::after,
q::before {
  content: "";
  content: none;
}

address {
  font-style: normal;
}

form {
  margin: 0;
  padding: 0;
}

form button {
  width: auto;
  display: inline-block;
  padding: 0.6em 2em;
  margin: 0;
  background-image: none;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 0;
  font-size: 1.6rem;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.3s ease 0s;
}

form button:hover, form button:focus {
  outline: none;
}

form button:hover {
  opacity: 0.7;
}

form button ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: none;
  background-image: none;
  font-family: inherit;
  font-size: 1.6rem;
  -webkit-appearance: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  border: 0.5px solid #c3c4c6;
}

@media (max-width: 767px) {
  input,
  textarea {
    width: 100%;
  }
}

textarea:hover, textarea:focus {
  outline: none;
}

textarea:focus {
  outline: none;
  box-shadow: none;
}

textarea ::-moz-focus-inner {
  padding: 0;
  border: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

input[type="submit"], input[type="button"] {
  width: auto;
  display: block;
  padding: 0;
  margin: 0;
  background: transparent;
  background-image: none;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  transition: all 0.2s ease 0s;
  border-radius: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: #f6921e;
  font-weight: bold;
  font-size: 1.8rem;
}

input[type="submit"]:hover, input[type="submit"]:focus, input[type="button"]:hover, input[type="button"]:focus {
  opacity: 0.7;
}

input[type="submit"] ::-moz-focus-inner, input[type="button"] ::-moz-focus-inner {
  padding: 0;
  border: none;
}

@media (max-width: 767px) {
  input[type="submit"], input[type="button"] {
    font-size: 1.6rem;
  }
}

.main {
  background: #fcf5ee;
}

/* =============================================================================


     font-face


============================================================================= */
@font-face {
  font-family: "Zen_Kaku_Gothic";
  src: url("../asset/font/Zen_Kaku_Gothic_Antique/ZenKakuGothicAntique-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Zen_Kaku_Gothic";
  src: url("../asset/font/Zen_Kaku_Gothic_Antique/ZenKakuGothicAntique-Medium.woff") format("woff");
  font-weight: 600;
}

.inner {
  margin: 0 auto;
  padding: 0;
  max-width: 100%;
  width: 1180px;
  height: inherit;
  position: relative;
}

.inner._big {
  width: 95%;
}

.inner._middle {
  width: 90%;
}

@media (max-width: 1228px) {
  .inner {
    padding-right: 24px;
    padding-left: 24px;
    min-width: unset;
  }
}

@media (max-width: 767px) {
  .inner {
    padding-right: 16px;
    padding-left: 16px;
  }
}

/* .main {
  margin-top: 120px;
  @include mq("sp") {
    margin-top: 80px;
  }
}
 */
/* =============================================================================


     btn


============================================================================= */
.btn {
  text-align: center;
  max-width: 320px;
  width: 100%;
  padding: 0.5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background: #f79421;
  transition: 0.2s;
  border-radius: 30px;
  position: relative;
  height: 60px;
}

.btn:hover {
  opacity: 0.7;
}

.btn span {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.btn span:before, .btn span:after {
  content: "";
  display: block;
  width: 8px;
  /*線の長さ*/
  height: 2px;
  /*線の太さa*/
  background: #5b3616;
  /*線の色*/
  position: absolute;
  top: calc(50% - (2px / 2));
  right: 0;
  border-radius: 100px;
  transform: rotate(45deg);
  /*角度*/
  transform-origin: calc(100% - (2px / 2)) 50%;
}

.btn span:after {
  transform: rotate(-45deg);
  /*角度*/
}

@media (max-width: 767px) {
  .btn {
    max-width: 490px;
  }
}

/* =============================================================================


     common


============================================================================= */
.main {
  overflow-x: hidden;
}

.submv {
  height: 370px;
  position: relative;
  background: #fad07d;
  padding-top: 100px;
}

.submv .inner {
  height: 100%;
}

@media (max-width: 767px) {
  .submv {
    height: 240px;
    padding-top: 60px;
  }
}

.submv-ttl {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 4.3rem;
  font-weight: bold;
  color: #704328;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 0;
  position: relative;
  z-index: 2;
}

@media (max-width: 1228px) {
  .submv-ttl {
    font-size: 3.6rem;
  }
}

@media (max-width: 767px) {
  .submv-ttl {
    font-size: 3rem;
  }
}

.submv-deco {
  position: absolute;
}

.submv-deco01 {
  width: 130px;
  left: 60px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767px) {
  .submv-deco01 {
    width: 60px;
    left: 20px;
    transform: none;
    top: 10px;
  }
}

.submv-deco02 {
  width: 80px;
  bottom: 40px;
  left: -100px;
}

@media (max-width: 767px) {
  .submv-deco02 {
    width: 40px;
    bottom: 20px;
    left: -50px;
  }
}

.submv-deco03 {
  width: 300px;
  bottom: 30px;
  right: 0;
}

@media (max-width: 767px) {
  .submv-deco03 {
    width: 150px;
    bottom: 10px;
  }
}

.section-ttlbox {
  text-align: center;
}

.section-enttl {
  color: #f7b525;
  font-size: 2rem;
  display: block;
}

@media (max-width: 767px) {
  .section-enttl {
    font-size: 1.6rem;
  }
}

.section-ttl {
  letter-spacing: 4.62px;
  font-size: 3.3rem;
  display: inline;
  position: relative;
  padding-bottom: 0.2em;
  background-image: url(../asset/images/common/border_y.svg);
  background-repeat: repeat-x;
  background-size: 30px;
  background-position: bottom;
  line-height: 2;
  /*   .br {
    background-image: url(../asset/images/common/border_y.svg);
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: 30px;
    padding-bottom: 0.2em;
    position: relative;
    line-height: 2;
  } */
}

@media (max-width: 767px) {
  .section-ttl {
    background-size: 20px;
  }
}

@media (max-width: 1228px) {
  .section-ttl {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .section-ttl {
    font-size: 2.6rem;
  }
}

.section-ttl span {
  color: #f7b525;
}

@media (max-width: 1228px) {
  .drawer {
    z-index: 40;
    z-index: 100;
    width: 100px;
    height: 100px;
    position: fixed;
    right: 0;
    top: 0;
    border-radius: 0px;
  }
}

@media (max-width: 767px) {
  .drawer {
    width: 60px;
    height: 60px;
  }
}

.drawer-close {
  display: none;
  position: fixed;
  z-index: 39;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.drawer-close.is-checked {
  display: block;
  opacity: 0.4;
}

.drawer-icon {
  display: none;
}

@media (max-width: 1228px) {
  .drawer-icon {
    position: relative;
    z-index: 41;
    text-align: center;
    cursor: pointer;
    background-color: inherit;
    display: block;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    font-weight: bold;
    border-radius: 0 0 0 40px;
  }
  .drawer-icon:focus {
    outline: none;
  }
  .drawer-icon.is-checked + body {
    overflow: hidden;
  }
}

.drawer-bars {
  /*   &:after {
    position: absolute;
    top: calc(100% + 0.5em);
    left: 50%;
    transform: translateX(-50%);
    content: "MENU";
    z-index: -1;
    color: #fff;
    font-size: 1.8rem;

    @include mq("tab") {
      font-size: 1.4rem;
    }
    @include mq("sp") {
      font-size: 1.2rem;
    }
  } */
}

@media (max-width: 1228px) {
  .drawer-bars {
    display: inline-block;
    width: 50px;
    height: 44px;
    position: relative;
    vertical-align: bottom;
    margin: auto;
    font-size: 1.8rem;
    top: 10px;
  }
}

@media (max-width: 767px) {
  .drawer-bars {
    top: 0;
  }
}

@media (max-width: 767px) {
  .drawer-bars {
    width: 30px;
    height: 24px;
  }
}

@media (max-width: 1228px) {
  .drawer-bar {
    position: absolute;
    right: 0;
    border-radius: 0px;
    display: block;
    background: #f7b525;
    width: 100%;
    height: 4px;
    transition: all 0.3s linear 0s;
  }
}

.drawer-bar:nth-of-type(1) {
  top: 0;
}

.drawer-bar:nth-of-type(2) {
  top: 20px;
}

@media (max-width: 1228px) {
  .drawer-bar:nth-of-type(2) {
    top: 10px;
  }
}

.drawer-bar:nth-of-type(3) {
  top: 40px;
}

@media (max-width: 1228px) {
  .drawer-bar:nth-of-type(3) {
    top: 20px;
  }
}

.is-checked .drawer-bar {
  background: #f7b525;
}

.is-checked .drawer-bar:nth-of-type(1) {
  top: 12px;
  transform: rotate(-45deg);
  width: 100%;
}

.is-checked .drawer-bar:nth-of-type(2) {
  background: transparent;
}

.is-checked .drawer-bar:nth-of-type(3) {
  top: 12px;
  transform: rotate(45deg);
  width: 100%;
}

.drawer-content.is-checked {
  top: 100px;
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

@media (max-width: 767px) {
  .drawer-content {
    top: 60px;
  }
  .drawer-content.is-checked {
    top: 60px;
  }
}

@media (max-width: 1228px) {
  .drawer-content {
    height: 100%;
    overflow: auto;
    position: fixed;
    top: 100px;
    right: 0;
    bottom: auto;
    left: auto;
    width: 300px;
    max-width: 100%;
    height: auto;
    background: #f7b525;
    transition: all 0.5s ease 0s;
    text-align: left;
    /*     color: #fff; */
    font-weight: 600;
    margin: auto;
  }
}

@media (max-width: 767px) {
  .drawer-content {
    min-width: unset;
  }
}

.drawer-content--right.is-checked {
  transform: 0;
}

@media (max-width: 1228px) {
  .drawer-content--right {
    transform: translateX(105%);
    left: auto;
    right: 0;
  }
}

@media (max-width: 1228px) {
  .drawer-content--left {
    transform: translateX(-105%);
    left: 0;
    right: auto;
  }
}

@media (max-width: 1228px) {
  .drawer-content--op {
    top: 100px;
    bottom: auto;
    height: calc(100% - 100px);
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-width: auto;
    opacity: 0;
    visibility: hidden;
    background: #fff;
  }
}

@media (max-width: 767px) {
  .drawer-content--op {
    top: 60px;
    height: calc(100% - 60px);
  }
}

.drawer-content--top {
  transform: translateY(-105%);
  top: 0px;
  bottom: auto;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-width: auto;
}

.drawer-bg {
  height: 100%;
}

.drawer-bg.is-checked {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0px;
  transition: 0.4s;
  background: rgba(0, 0, 0, 0.4);
}

@media (max-width: 1228px) {
  .drawer-bg.is-checked {
    top: 100px;
  }
}

@media (max-width: 767px) {
  .drawer-bg.is-checked {
    top: 60px;
  }
}

.drawer-box {
  padding-top: 100px;
}

@media (max-width: 767px) {
  .drawer-box {
    padding-top: 60px;
  }
}

.drawer-navbox {
  position: relative;
  height: 100%;
}

.g-nav {
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .g-nav {
    max-width: 1000px;
    margin: 0 auto;
    display: block;
  }
}

.g-nav-list {
  display: inline-block;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  gap: 80px;
}

@media (max-width: 1228px) {
  .g-nav-list {
    font-size: 2rem;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
  }
}

.g-nav-item {
  position: relative;
  display: flex;
  flex-direction: column;
}

.g-nav-item-link {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  text-align: center;
  transition: 0.2s;
  padding: 0.75em 0;
  position: relative;
  justify-content: center;
  height: 100%;
}

.g-nav-item-link:before {
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background: #5b3616;
}

a.g-nav-item-link:hover {
  opacity: 0.7;
}

/* =============================================================================


     floating


============================================================================= */
.floating {
  position: fixed;
  right: 0;
  bottom: 40px;
  z-index: 10;
}

.floating a {
  background: #8dab77;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  text-align: center;
  width: 216px;
  height: 140px;
  gap: 8px;
  font-size: 2rem;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}

.floating a:hover {
  opacity: 0.7;
}

.floating a img {
  width: 32px;
}

@media (max-width: 767px) {
  .floating {
    bottom: 0;
    left: 0;
    right: auto;
    width: 100%;
  }
  .floating a {
    width: 100%;
    height: 50px;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}

/* =============================================================================


    inner-page


============================================================================= */
.ir-section-txt {
  padding: 80px 0 0px;
  max-width: 700px;
  text-align: left;
  margin: auto;
  line-height: 2;
}

@media (max-width: 767px) {
  .ir-section-txt {
    padding: 60px 0 0px;
  }
}

.page {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
  margin-top: 100px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.page-numbers {
  cursor: pointer;
  position: relative;
  align-items: center;
  background: inherit;
  display: flex;
  height: 40px;
  transition: 0.3s;
  justify-content: center;
  width: 40px;
  border: none;
  background: #fff;
  border-radius: 50%;
  font-size: 2rem;
}

@media (max-width: 767px) {
  .page-numbers {
    height: 32px;
    width: 32px;
    font-size: 1.6rem;
  }
}

.page-numbers + .page-numbers {
  margin-left: 20px;
}

@media (max-width: 767px) {
  .page-numbers + .page-numbers {
    margin-left: 8px;
  }
}

.page-numbers.current {
  background: #133e8b;
  color: #fff;
  cursor: default;
  opacity: 1;
}

.page-numbers:hover {
  background: #133e8b;
  color: #fff;
  opacity: 1;
}

.page-numbers.prev,
.page-numbers.next {
  border: none;
  background: #313139;
  width: 40px;
  height: 40px;
  position: relative;
}

.page-numbers.prev:before,
.page-numbers.next:before {
  content: "";
  width: 10px;
  height: 10px;
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-135deg);
}

.page-numbers.prev:hover,
.page-numbers.next:hover {
  transform: scale(1.2);
}

.page-numbers.next {
  transform: scale(-1, 1);
}

.page-numbers.next:hover {
  transform: scale(-1.2, 1.2);
}

#dialog {
  background: rgba(0, 0, 0, 0.4);
  height: 100vh;
  position: fixed;
  top: 0;
  transition: 0.3s;
  visibility: hidden;
  width: 100vw;
  z-index: 9999999999;
}

#dialog .dialog-box {
  background: repeating-linear-gradient(-45deg, #fffc4e, #fffc4e 5px, white 5px, white 10px);
  box-shadow: 2px 2px 4px #333;
  left: 50%;
  padding: 30px;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  z-index: 9999999999;
  height: 360px;
  max-width: calc(100% - 16px);
}

@media (max-width: 767px) {
  #dialog .dialog-box {
    padding: 16px;
  }
}

.choose_box {
  height: 100%;
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  padding: 40px 0;
}

@media (max-width: 767px) {
  #dialog .dialog-box {
    min-width: 250px;
  }
}

#dialog .dialog-text {
  font-size: 2rem;
  font-weight: bold;
}

@media (max-width: 1014px) {
  #dialog .dialog-text {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

#dialog button#cancel {
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: #f9c0cd;
  margin: auto;
  margin-top: 30px;
  background: #5b3616;
  position: relative;
  width: 300px;
  line-height: normal;
  padding: 0.25em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2rem;
  border-radius: 18px;
  border: 3px solid #5b3616;
  letter-spacing: 3.9px;
  transition: 0.2s;
  text-align: center;
  color: inherit;
}

#dialog button:hover {
  opacity: 0.7;
}

/* =============================================================================


     footer


============================================================================= */
#footer {
  position: relative;
}

@media (max-width: 767px) {
  #footer {
    overflow: hidden;
    padding-top: 60px;
  }
}

.footer-container {
  position: relative;
  padding: 140px 0 20px;
}

@media (max-width: 767px) {
  .footer-container {
    padding-top: 60px;
    margin-bottom: 100px;
  }
}

.footer-container:before {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  background: #f8f39a;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw + 1000px);
  height: 100%;
}

@media (max-width: 767px) {
  .footer-container:before {
    height: calc(100% + 100px);
    top: 0;
    transform: translateX(-50%);
  }
}

.footer-deco01 {
  width: 376px;
  position: absolute;
  left: -200px;
  top: -250px;
}

@media (max-width: 767px) {
  .footer-deco01 {
    left: -20px;
    width: 28vw;
    top: -100px;
  }
}

.footer-deco02 {
  width: 600px;
  position: absolute;
  right: -300px;
  top: -240px;
}

@media (max-width: 767px) {
  .footer-deco02 {
    right: -120px;
    width: 49vw;
    top: -100px;
  }
}

.footer-logo {
  width: 400px;
}

@media (max-width: 767px) {
  .footer-logo {
    width: 80%;
    max-width: 400px;
  }
}

.footer-content {
  margin-top: 60px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 180px;
}

@media (max-width: 1228px) {
  .footer-content {
    gap: 60px;
  }
}

@media (max-width: 767px) {
  .footer-content {
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
  }
}

.footer-address-item {
  padding-bottom: 1em;
  letter-spacing: 0;
}

.footer-address-item + .footer-address-item {
  border-top: 1px solid #5b3616;
  padding-top: 1em;
}

.footer-address {
  width: 400px;
}

@media (max-width: 767px) {
  .footer-address {
    max-width: 400px;
    width: 100%;
  }
}

.footer-address-name {
  font-size: 2rem;
  font-weight: bold;
}

.footer-address-txt {
  line-height: 1.8;
}

.footer-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-weight: bold;
  gap: 24px;
}

.footer-link {
  position: relative;
  padding-left: 1em;
  transition: 0.2s;
}

.footer-link:hover {
  opacity: 0.7;
}

.footer-link:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  width: 0.75em;
  height: 1px;
  background: #5b3616;
}

.footer-copy {
  margin-top: 60px;
  font-size: 1.4rem;
}

.floating-btns {
  display: none;
}

@media (max-width: 767px) {
  .floating-btns {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    z-index: 10;
  }
}

.floating-btn {
  width: 50%;
  transition: 0.2s;
  display: block;
}

.floating-btn:hover {
  opacity: 0.7;
}

/* =============================================================================


     header


============================================================================= */
header {
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0px;
  height: 100px;
  transition: all 0.2s ease-in 0s;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 2px 2px 4px -2px #000;
}

@media (max-width: 1228px) {
  header {
    width: 100%;
  }
}

@media (max-width: 767px) {
  header {
    width: 100%;
    height: 60px;
  }
}

/* body[data-scroll="true"] header {
  height: 80px;
  background: #489fdf;
}
 */
.header-logo {
  transition: 0.2s;
  width: 320px;
}

.header-logo:hover {
  opacity: 0.7;
}

.header-logo img {
  display: block;
}

@media (max-width: 1228px) {
  .header-logo {
    width: 260px;
  }
}

@media (max-width: 767px) {
  .header-logo {
    width: 220px;
  }
}

.header-logo .header-logoimg {
  width: 100%;
  display: block;
}

.header-contents {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;
}

@media (max-width: 1228px) {
  .header-contents {
    flex-direction: row;
  }
}

.header-link {
  transition: 0.2s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 101;
}

.header-link:hover {
  opacity: 0.7;
}

.header-nav-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  z-index: 100;
  width: 100%;
  gap: 60px;
}

@media (max-width: 767px) {
  .header-nav-container {
    margin-left: 0;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.header-info {
  margin-top: auto;
  width: 100%;
}

@media (max-width: 767px) {
  .header-info {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    /*     display: none; */
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    max-width: 600px;
  }
}

.header-telbtn {
  background: #f7b525;
  border: 2px solid #5b3616;
  border-radius: 20px;
  text-align: center;
  transition: 0.2s;
  width: 300px;
  height: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: 0.2s;
}

.header-telbtn:hover {
  opacity: 0.7;
}

@media (max-width: 1228px) {
  .header-telbtn {
    margin-right: 100px;
  }
}

@media (max-width: 767px) {
  .header-telbtn {
    display: none;
  }
}

.header-tel-ttl {
  font-size: 3rem;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.header-tel-ttl img {
  width: 24px;
  margin-right: 20px;
  margin-top: 4px;
}

@media (max-width: 767px) {
  .header-tel-ttl {
    display: none;
  }
}

.header-tel-txt {
  font-size: 1.4rem;
}

/* =============================================================================


     subfooter


============================================================================= */
/* =============================================================================


     .sub-contact


============================================================================= */
.sub-contact {
  padding: 100px 0 140px;
  text-align: center;
}

@media (max-width: 767px) {
  .sub-contact {
    padding: 40px 0 60px;
    overflow: hidden;
  }
}

.sub-contact-container {
  position: relative;
  max-width: 1000px;
  margin: auto;
  padding: 40px;
  margin-top: 40px;
  z-index: 1;
}

.sub-contact-container:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 30px;
  width: 100%;
  height: calc(100% - 60px);
}

.sub-contact-txt {
  text-align: center;
  font-weight: bold;
  font-size: 2.8rem;
}

@media (max-width: 767px) {
  .sub-contact-txt {
    font-size: 2.3rem;
  }
}

.sub-contact-txt span {
  color: #f7b525;
}

.sub-contact-yen {
  margin-top: 40px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  font-weight: bold;
  font-size: 3rem;
  gap: 60px;
  position: relative;
}

@media (max-width: 767px) {
  .sub-contact-yen {
    flex-direction: column;
    gap: 40px;
    font-size: 2.2rem;
  }
}

.sub-contact-yen:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  background: #5b3616;
  width: 15px;
  height: 15px;
}

@media (max-width: 767px) {
  .sub-contact-yen:before {
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }
}

.sub-contact-yen dt {
  width: calc(50% - 30px);
  text-align: right;
}

@media (max-width: 767px) {
  .sub-contact-yen dt {
    text-align: center;
    width: 100%;
  }
}

.sub-contact-yen dd {
  width: calc(50% - 30px);
  font-size: 4rem;
  text-align: left;
}

.sub-contact-yen dd > span {
  padding: 0 0.5em;
}

@media (max-width: 767px) {
  .sub-contact-yen dd {
    text-align: center;
    width: 100%;
    font-size: 3.3rem;
  }
}

.sub-contact-yen span {
  background: linear-gradient(transparent 60%, #fff57f 40%);
}

.sub-contact-yen span > span {
  color: #f7b525;
}

.sub-contact-btnbox {
  margin: 40px auto 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 50px;
}

@media (max-width: 767px) {
  .sub-contact-btnbox {
    flex-direction: column;
    gap: 20px;
  }
}

.sub-contact-deco {
  width: 0;
}

.sub-contact-btn {
  background: #e1e067;
  border-radius: 30px;
  text-align: center;
  width: 400px;
  height: 120px;
  color: #5b3616;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

@media (max-width: 767px) {
  .sub-contact-btn {
    max-width: 400px;
    height: 80px;
    width: 100%;
  }
}

.sub-contact-btn._tel {
  background: #f7b525;
}

.sub-contact-btn._tel img {
  width: 40px;
}

@media (max-width: 767px) {
  .sub-contact-btn._tel img {
    width: 28px;
  }
}

.sub-contact-btn:hover {
  opacity: 0.7;
}

.sub-contact-btn span {
  width: 100%;
  display: block;
  font-size: 1.6rem;
  font-weight: normal;
}

.sub-contact-btn img {
  display: inline-block;
  width: 50px;
  margin-right: 18px;
}

@media (max-width: 767px) {
  .sub-contact-btn img {
    width: 34px;
  }
}

@media (max-width: 767px) {
  .sub-contact-btn {
    max-width: 400px;
    width: 95%;
  }
}

.sub-contact-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.sub-contact-btntxt {
  font-size: 3.2rem;
  letter-spacing: 0;
}

._tel .sub-contact-btntxt {
  font-size: 3.4rem;
}

@media (max-width: 1228px) {
  ._tel .sub-contact-btntxt {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  ._tel .sub-contact-btntxt {
    font-size: 2.8rem;
  }
}

@media (max-width: 1228px) {
  .sub-contact-btntxt {
    font-size: 2.6rem;
  }
}

@media (max-width: 767px) {
  .sub-contact-btntxt {
    font-size: 2.2rem;
  }
}

.sub-contact-deco01 {
  width: 120px;
  position: absolute;
  top: -60px;
  left: -120px;
}

.sub-contact-deco02 {
  width: 280px;
  position: absolute;
  top: -260px;
  right: -200px;
}

@media (max-width: 767px) {
  .sub-contact-deco02 {
    right: -100px;
    top: -140px;
    width: 140px;
  }
}

.sub-contact-deco03 {
  width: 120px;
  position: absolute;
  right: -20px;
}

@media (max-width: 767px) {
  .sub-contact-deco03 {
    width: 30px;
    right: -10px;
  }
}

.sub-contact-deco04 {
  width: 150px;
  position: absolute;
  left: -120px;
  bottom: 0;
}

@media (max-width: 767px) {
  .sub-contact-deco04 {
    width: 70px;
    left: -60px;
  }
}

.pc-dis {
  display: none;
}

@media (max-width: 1366px) {
  .pc-dis {
    display: block;
  }
}

.tab-none {
  display: block;
}

@media (max-width: 1228px) {
  .tab-none {
    display: none;
  }
}

.tab-dis {
  display: none;
}

@media (max-width: 1228px) {
  .tab-dis {
    display: block;
  }
}

.sp-none {
  display: block;
}

@media (max-width: 767px) {
  .sp-none {
    display: none;
  }
}

.sp-dis {
  display: none;
}

@media (max-width: 767px) {
  .sp-dis {
    display: block;
  }
}
